import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import unfurl from '~prismic/unfurl/category-overview';
import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';
import { Container } from '@material-ui/core';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import CategoryTopNavigation from '~containers/browse-library/CategoryOverviewPage/CategoryTopNavigation';
import CategoryLayout from '~containers/browse-library/CategoryOverviewPage/CategoryLayout';
import makePageEnabledEnforcer from '~layout/makePageEnabledEnforcer';

const IS_BROWSE_LIBRARY_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BROWSE_LIBRARY);

const CategoryOverviewPage = ({ data, pageContext }) => {
  const { seo, title, color, categories } = unfurl(data);
  const { uid: pageUid, browseListUid: parentPageUid } = pageContext;
  const pagePath = `/library/${parentPageUid}/${pageUid}`;

  const links = useMemo(
    () => [
      {
        title: 'Browse',
        mobileTitle: 'Experience the Library',
        url: `/library/${parentPageUid}`
      },
      {
        title,
        mobileTitle: title,
        url: `/library/${parentPageUid}/${pageUid}`,
        isActive: true
      }
    ],
    [title, pageUid, parentPageUid]
  );

  return (
    <Page hideMarketingSecondaryHeader allowStickyContent>
      <SEO {...seo} />
      <Container>
        <CategoryTopNavigation links={links} />
        <CategoryLayout color={color} categories={categories} pagePath={pagePath} />
      </Container>
    </Page>
  );
};

CategoryOverviewPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({
    uid: PropTypes.string,
    browseListUid: PropTypes.string
  }).isRequired
};

export default makePageEnabledEnforcer(
  IS_BROWSE_LIBRARY_ENABLED,
  withPrismicPreview(CategoryOverviewPage)
);

export const query = graphql`
  query ($uid: String!) {
    prismicBrowseSection(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_image {
          alt
          url
        }
        seo_canonical_url
        seo_keywords
        highlight_color
        title
        categories {
          category {
            document {
              ... on PrismicBrowseCategory {
                uid
                data {
                  title
                  section_title
                  parent_category {
                    uid
                    document {
                      ... on PrismicBrowseCategory {
                        data {
                          title
                          section_title
                        }
                      }
                    }
                  }
                  category_date
                  category_end_date
                  resources {
                    resource {
                      uid
                      url
                      type
                      document {
                        ... on PrismicPlaylist {
                          type
                          data {
                            resource_medium
                            title
                            description {
                              text
                            }
                            cover_image_without_text {
                              url
                              alt
                            }
                            cover_image {
                              url
                              alt
                            }
                            videos {
                              video {
                                document {
                                  ... on PrismicVideo {
                                    data {
                                      length_in_seconds
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on PrismicPodcastPlaylist {
                          type
                          data {
                            resource_medium
                            podcast_title {
                              text
                            }
                            podcast_description {
                              text
                            }
                            cover_image_without_text {
                              url
                              alt
                            }
                            card_thumbnail {
                              url
                              alt
                            }
                            episodes {
                              ... on PrismicPodcastPlaylistDataEpisodesEpisode {
                                primary {
                                  series_title {
                                    text
                                  }
                                }
                                items {
                                  podcast_episode {
                                    document {
                                      ... on PrismicPodcastEpisode {
                                        uid
                                        data {
                                          length_in_seconds
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on PrismicPodcastEpisode {
                          type
                          data {
                            resource_medium
                            title
                            description {
                              text
                            }
                            cover_image {
                              alt
                              url
                            }
                            cover_image_without_text {
                              alt
                              url
                            }
                            length_in_seconds
                            podcast_playlist {
                              document {
                                ... on PrismicPodcastPlaylist {
                                  uid
                                  url
                                  data {
                                    card_thumbnail {
                                      alt
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on PrismicMaterial {
                          type
                          data {
                            resource_medium
                            title
                            material_type
                            required_subscription_plan
                            file {
                              url
                            }
                            preview_image {
                              alt
                              url
                            }
                            seo_image {
                              alt
                              url
                            }
                            material_description: description
                          }
                        }
                        ... on PrismicArticle {
                          type
                          data {
                            title
                            description {
                              text
                            }
                            cover_image_without_text {
                              url
                              alt
                            }
                            seo_image {
                              url
                              alt
                            }
                            resource_medium
                            read_time
                            parent_page {
                              url
                              uid
                            }
                          }
                        }
                        ... on PrismicParshat {
                          url
                          data {
                            title
                            resource_medium
                            slices {
                              ... on PrismicParshatDataSlicesHeroTextToc {
                                slice_type
                                primary {
                                  title
                                  description
                                }
                              }
                            }
                            seo_description
                            seo_image {
                              alt
                              url
                            }
                          }
                        }
                        ... on PrismicHolidayRedesign {
                          type
                          url
                          data {
                            title
                            resource_medium
                            seo_description
                            seo_image {
                              alt
                              url
                            }
                          }
                        }
                      }
                    }
                    custom_title
                    custom_description {
                      text
                    }
                    custom_thumbnail {
                      alt
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
